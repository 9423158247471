@brand-primary: #AE5551;
@brand-success: #8CB6ED;
@brand-info: #E0CFAF;
@brand-warning: #F7C348;
@brand-danger:  #F77E86;

@celestia: #EBDCA2;
@luna: #7F69EE;
@aeternum: #113198;
@translucentgold: rgba(225,163,77,.8);

// Euphorius colors
@mesa:	#B75E3E;
@stone: #4F424C;
@goldsand: #C2BA95;
@goldweave: #DCA14B;

@gray-base:              @mesa;
@gray-darker:            darken(@mesa, 20%);
@gray-dark:              darken(@mesa, 10%);
@gray:                   @mesa;
@gray-light:             lighten(@mesa, 10%);
@gray-lighter:           darken(@mesa, 20%);

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
@body-bg:               @goldweave;
//** Global text color on `<body>`.
@text-color:            #FFFFFF;

#tm-header {
	background: darken(@brand-primary,20%);
}

//** Global textual link color.
@link-color:           #DDA9A2;
@link-hover-color:     #F5B476;
//** Link hover decoration.
@link-hover-decoration: none;

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

@padding-base-vertical:     6px;
@padding-base-horizontal:   10px;

@padding-large-vertical:    10px;
@padding-large-horizontal:  14px;

@padding-small-vertical:    3px;
@padding-small-horizontal:  8px;

@padding-xs-vertical:       1px;
@padding-xs-horizontal:     5px;

@line-height-large:         1.3333333; // extra decimals for Win 8.1 Chrome
@line-height-small:         1.5;

@border-radius-base:        4px;
@border-radius-large:       6px;
@border-radius-small:       2px;

//** Global color for active items (e.g., navs or dropdowns).
@component-active-color:    #fff;
//** Global background color for active items (e.g., navs or dropdowns).
@component-active-bg:       @brand-primary;

//** Width of the `border` for generating carets that indicator dropdowns.
@caret-width-base:          4px;
//** Carets increase slightly in size for larger components.
@caret-width-large:         5px;

@panel-color: @stone;
@panel-bg:                    darken(@panel-color,10%);
@panel-body-padding:          15px;
@panel-heading-padding:       10px 15px;
@panel-footer-padding:        @panel-heading-padding;
@panel-border-radius:         @border-radius-base;

//** Border color for elements within panels
@panel-inner-border:          lighten(@panel-bg, 10%);
@panel-footer-bg:             darken(@panel-bg, 5%);

@panel-default-text:          @text-color;
@panel-default-border:        @panel-inner-border;
@panel-default-heading-bg:    @panel-footer-bg;

@panel-primary-text:          #fff;
@panel-primary-border:        @brand-primary;
@panel-primary-heading-bg:    @brand-primary;

@panel-success-text:          @state-success-text;
@panel-success-border:        @state-success-border;
@panel-success-heading-bg:    @state-success-bg;

@panel-info-text:             @state-info-text;
@panel-info-border:           @state-info-border;
@panel-info-heading-bg:       @state-info-bg;

@panel-warning-text:          @state-warning-text;
@panel-warning-border:        @state-warning-border;
@panel-warning-heading-bg:    @state-warning-bg;

@panel-danger-text:           @state-danger-text;
@panel-danger-border:         @state-danger-border;
@panel-danger-heading-bg:     @state-danger-bg;

// Navbar
@navbar-default-bg: #332931;
@navbar-default-link-color: #F9F1EF;
@navbar-default-link-hover-color: darken(@navbar-default-link-color, 13%);
 
// Navbar toggle
@navbar-default-toggle-hover-bg:          lighten(@stone,10%);
@navbar-default-toggle-icon-bar-bg:      lighten(@stone,40%);
@navbar-default-toggle-border-color:      darken(@stone,10%);

//.navbar-default {
//	background: url("../share/topnav-bg.png") repeat-x;
//};

//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
@dropdown-bg:                    darken(@navbar-default-bg,10%);
//** Dropdown menu `border-color`.
@dropdown-border:                lighten(@navbar-default-bg,10%);
//** Dropdown menu `border-color` **for IE8**.
@dropdown-fallback-border:       #444;
//** Divider color for between dropdown items.
@dropdown-divider-bg:            rgba(255,255,255,0.1);

//** Dropdown link text color.
@dropdown-link-color:            @navbar-default-link-color;
//** Hover color for dropdown links.
@dropdown-link-hover-color:      @navbar-default-link-hover-color;
//** Hover background for dropdown links.
@dropdown-link-hover-bg:         @component-active-bg;

//** Active dropdown menu item text color.
@dropdown-link-active-color:     #fff;
//** Active dropdown menu item background color.
@dropdown-link-active-bg:        @component-active-bg;

//** Disabled dropdown menu item background color.
@dropdown-link-disabled-color:   @gray-light;

//** Text color for headers within dropdown menus.
@dropdown-header-color:          @gray-light;

//** Deprecated `@dropdown-caret-color` as of v3.1.0
@dropdown-caret-color:           #000;

//== Wells
//
//##

@well-bg:                     @gray-darker;
@well-border:                 none;

// Comments 
#comments-head {
	background: @panel-bg;
	border: 1px solid lighten(@panel-bg,20%);
}

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

@btn-font-weight:                normal;

@btn-default-color:              #FFF;
@btn-default-bg:                 @stone;
@btn-default-border:             @btn-default-bg;

@btn-primary-color:              @btn-default-color;
@btn-primary-bg:                 @brand-primary;
@btn-primary-border:             @btn-primary-bg;

@btn-success-color:              @btn-default-color;
@btn-success-bg:                 @brand-success;
@btn-success-border:             @btn-success-bg;

@btn-info-color:                 @btn-default-color;
@btn-info-bg:                    @brand-info;
@btn-info-border:                @btn-info-bg;

@btn-warning-color:              @btn-default-color;
@btn-warning-bg:                 @brand-warning;
@btn-warning-border:             @btn-warning-bg;

@btn-danger-color:               @btn-default-color;
@btn-danger-bg:                  @brand-danger;
@btn-danger-border:              @btn-danger-bg;

@btn-link-disabled-color:        @gray-light;

// Allows for customizing button radius independently from global border radius
@btn-border-radius-base:         @border-radius-base;
@btn-border-radius-large:        @border-radius-large;
@btn-border-radius-small:        @border-radius-small;

//== Forms
//
//##

//** `<input>` background color
@input-bg:                       #2B3040;
//** `<input disabled>` background color
@input-bg-disabled:              @gray-lighter;

//** Text color for `<input>`s
@input-color:                    @text-color;
//** `<input>` border color
@input-border:                  @celestia;

// TODO: Rename `@input-border-radius` to `@input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
@input-border-radius:            @border-radius-base;
//** Large `.form-control` border radius
@input-border-radius-large:      @border-radius-large;
//** Small `.form-control` border radius
@input-border-radius-small:      @border-radius-small;

//** Border color for inputs on focus
@input-border-focus:             #66afe9;

//** Placeholder text color
@input-color-placeholder:        @gray-light;

//** Default `.form-control` height
@input-height-base:              (@line-height-computed + (@padding-base-vertical * 2) + 2);
//** Large `.form-control` height
@input-height-large:             (ceil(@font-size-large * @line-height-large) + (@padding-large-vertical * 2) + 2);
//** Small `.form-control` height
@input-height-small:             (floor(@font-size-small * @line-height-small) + (@padding-small-vertical * 2) + 2);

//** `.form-group` margin
@form-group-margin-bottom:       15px;

@legend-color:                   @text-color;
@legend-border-color:            @gray-dark;

//** Background color for textual input addons
@input-group-addon-bg:           @gray-lighter;
//** Border color for textual input addons
@input-group-addon-border-color: @input-border;

//** Disabled cursor for form controls and buttons.
@cursor-disabled:                not-allowed;
@font-family-sans-serif: "Montserrat Light", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;
@font-family-navbar: "Troika", sans-serif;
@font-family-standard: "Helvetica Neue", Helvetica, Arial, sans-serif;

@font-size-base:          14px;
@font-size-large:         ceil((@font-size-base * 1.25)); // ~18px
@font-size-small:         ceil((@font-size-base * 0.85)); // ~12px

@font-size-h1:            floor((@font-size-base * 2.6)); // ~36px
@font-size-h2:            floor((@font-size-base * 2.15)); // ~30px
@font-size-h3:            ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4:            ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

@line-height-base:        1.5;
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px

@headings-font-family:    @font-family-navbar;
@headings-font-weight:    500;
@headings-line-height:    1.1;
@headings-color:          inherit;

.panel-heading {
  font-family: @font-family-navbar !important;
  font-size: 26px;
}

.navbar-default {
  font-family: @font-family-navbar;
  font-size: 22px;
  };
  
#main-content {
	h1, h2, h3, h4, h5, h6 {
		color: lighten(@mesa, 36%);
	};
};
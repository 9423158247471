body {
	background-image: url(../share/_bg.png);
}

.content-panel {
	background: darken(@stone, 10%);
	border-radius: @border-radius-base;
	border: 1px solid @mesa;
}

#comments-head {
	border-radius: @border-radius-base;
}

.post-read-more a {
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
}

article h1 {
	text-shadow: none;
}

.pvfm-link {
	background: rgba(78, 13, 122, 0.6);
}

.friends-link {
	background: rgba(94,153,198,0.41);
}

.navbar-brand {
	padding-top: 2px;
}

.dl-choices ul {
	list-style-type: none;
	padding-left: 0;
}

header .navbar {
	margin-bottom: 0;
	border-radius: 0;
	border-left: 0;
	border-right: 0;
	}

// Rhythm is Magic

.dl-rim {
	color: #F2DAF2;
	background: #402440 url("../share/dlbg-original.png") bottom left no-repeat;
	}
	
.dl-rim h1, .dl-rim h2, .dl-rim h3 {
	color: #C2EDF0;
	text-shadow: none;
	}
	
.dl-rim a:link, .dl-rim a:visited {
	color: #C2EDF0;
	}
	
.dl-rim .dl-choices li {
	border-color: #422B42 !important;
	}
	
// Chrystalize

.dl-chrys {
	color: #FFFFFF;
	background: #2D5F6F url("../share/dlbg-chrys.png") bottom left no-repeat;
	}
	
.dl-chrys h1, .dl-chrys h2, .dl-chrys h3 {
	color: #C4EFF8;
	text-shadow: none;
	}
	
.dl-chrys a:link, .dl-chrys a:visited {
	color: #C4EFF8;
	}

.dl-chrys .dl-choices li {
	border-color: #78BBC5 !important;
	}	
	
// AO

.dl-ao {
	background: #050E2A url("../share/dlbg-ao.png") bottom left no-repeat;
	}
	
.dl-ao h1, .dl-ao h2, .dl-ao h3 {
	color: #FAE77A;
	text-shadow: none;
	}
	
.dl-ao a:link, .dl-ao a:visited {
	color: #FAE77A;
	}

.fa {
	padding-right: 0.4em;
	}
	
button .fa {
	padding-right: none;
	}
	
.panel-heading label {
	margin-bottom: 0;
	font-weight: normal;
	}
	
h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	}

article {
	margin-bottom: 1em;
	}

#content-comments {
	padding-top: 0.5em !important;
	}
	
.articleBody {
	margin-top: 0.5em;
	}
	
#content li {
	padding: 0.7em 0 0.5em 0;
	border-bottom: 1px solid lighten(@mesa, 10%);
	}
#content li article {
	margin-bottom: 0.5em;
	}
	
#tm-hero {
	background: #441112 url("http://i.imgur.com/DPlrT7g.png") center no-repeat;
    border-bottom: 1px solid #666666;
	}
	
#main-content {
	margin-top: 10px !important;
	}
	
footer {
	margin: 0.5em 0 0.8em 0;
	a:link, a:visited, a:active, a:hover {
		color: @stone;
	}
	}
	
.sidebar-euphorius {
		.panel-body {
			background: url("../share/euphorius-panel.png") center no-repeat;
		}
}
	
	
/* Comments */
.comments-list li {
    margin-bottom: 0.5em;
	border-bottom: 1px solid rgba(255,255,255,0.2) !important;
}

.comments-list li .post-meta {
	padding-bottom: 5px;
	color: rgba(255,255,255,0.3);
	}

.comments-list li h4 {
	margin-bottom: 0.3em;
	}

ol.comments-list, .comments-list li {
        list-style-type: none;
}

ol.comments-list {
        padding-left: 0 !important;
}

button[disabled], html input[disabled] {
 color: #51587A !important;
 border-color: #807C6E !important;
 }

fieldset {
 border: none;
 padding: 0.4em;
}

button, input, optgroup, select, textarea {
 background: #2B3040;
 border: 1px solid #D9BF84;
 }

textarea, input {
 padding: 0.3em;
}

#paginator {
 margin-top: 0.5em;
}

#tm-hero-right {
 font-family: "Exo 2", "Exo 2.0", Impact, sans-serif;
}

#tm-hero-right img {
 padding-top: 0.7em;
 padding-bottom: 0.4em;
}